export const countries = [
  { "id": "1", "name": "Nepal" },
  { "id": "2", "name": "Pakistan" },
  { "id": "3", "name": "India" },
  { "id": "4", "name": "Philippine" },
  { "id": "5", "name": "Sri Lanka" },
  { "id": "6", "name": "Bangladesh" }
]

export const state = [
  { "id": "101", "name": "Bagmati", "country_id": "1" },
  { "id": "102", "name": "Gandaki", "country_id": "1" },
  { "id": "103", "name": "Province No. 1", "country_id": "1" },
  { "id": "104", "name": "Province No. 2", "country_id": "1" },
  
  { "id": "201", "name": "Khyber Pakhtunkhwa", "country_id": "2" },
  { "id": "202", "name": "Punjab", "country_id": "2" },
  { "id": "203", "name": "Sindh", "country_id": "2" },
  { "id": "204", "name": "Balochistan", "country_id": "2" },
  
  { "id": "301", "name": "Maharashtra", "country_id": "3" },
  { "id": "302", "name": "West Bengal", "country_id": "3" },
  { "id": "303", "name": "Uttar Pradesh", "country_id": "3" },
  { "id": "304", "name": "Tamil Nadu", "country_id": "3" },

  { "id": "401", "name": "Central Luzon", "country_id": "4" },
  { "id": "402", "name": "National Capital Region", "country_id": "4" },
  { "id": "403", "name": "Western Visayas", "country_id": "4" },
  { "id": "404", "name": "Mindanao", "country_id": "4" },
  
  { "id": "501", "name": "Western Province", "country_id": "5" },
  { "id": "502", "name": "Southern Province", "country_id": "5" },
  { "id": "503", "name": "Central Province", "country_id": "5" },
  { "id": "504", "name": "Eastern Province", "country_id": "5" },
  
  { "id": "601", "name": "Dhaka Division", "country_id": "6" },
  { "id": "602", "name": "Chittagong Division", "country_id": "6" },
  { "id": "603", "name": "Khulna Division", "country_id": "6" },
  { "id": "604", "name": "Rajshahi Division", "country_id": "6" }
];

export const cities = [
  { "id": "1001", "name": "Kathmandu", "state_id": "101" },
  { "id": "1002", "name": "Pokhara", "state_id": "102" },
  { "id": "1003", "name": "Biratnagar", "state_id": "103" },
  { "id": "1004", "name": "Janakpur", "state_id": "104" },

  { "id": "2001", "name": "Peshawar", "state_id": "201" },
  { "id": "2002", "name": "Lahore", "state_id": "202" },
  { "id": "2003", "name": "Karachi", "state_id": "203" },
  { "id": "2004", "name": "Quetta", "state_id": "204" },

  { "id": "3001", "name": "Mumbai", "state_id": "301" },
  { "id": "3002", "name": "Kolkata", "state_id": "302" },
  { "id": "3003", "name": "Lucknow", "state_id": "303" },
  { "id": "3004", "name": "Chennai", "state_id": "304" },

  { "id": "4001", "name": "Angeles", "state_id": "401" },
  { "id": "4002", "name": "Quezon City", "state_id": "402" },
  { "id": "4003", "name": "Iloilo City", "state_id": "403" },
  { "id": "4004", "name": "Davao City", "state_id": "404" },

  { "id": "5001", "name": "Colombo", "state_id": "501" },
  { "id": "5002", "name": "Galle", "state_id": "502" },
  { "id": "5003", "name": "Kandy", "state_id": "503" },
  { "id": "5004", "name": "Batticaloa", "state_id": "504" },

  { "id": "6001", "name": "Dhaka", "state_id": "601" },
  { "id": "6002", "name": "Chittagong", "state_id": "602" },
  { "id": "6003", "name": "Khulna", "state_id": "603" },
  { "id": "6004", "name": "Rajshahi", "state_id": "604" }
];

export const police_stations = [
  { "id": "20001", "name": "Durbar Marg Police Station", "city_id": "1001" },
  { "id": "20002", "name": "Pokhara Police Station", "city_id": "1002" },
  { "id": "20003", "name": "Biratnagar Police Station", "city_id": "1003" },
  { "id": "20004", "name": "Janakpur Police Station", "city_id": "1004" },

  { "id": "20005", "name": "University Town Police Station", "city_id": "2001" },
  { "id": "20006", "name": "Anarkali Police Station", "city_id": "2002" },
  { "id": "20007", "name": "Clifton Police Station", "city_id": "2003" },
  { "id": "20008", "name": "Civil Lines Police Station", "city_id": "2004" },

  { "id": "20009", "name": "Worli Police Station", "city_id": "3001" },
  { "id": "20010", "name": "Park Street Police Station", "city_id": "3002" },
  { "id": "20011", "name": "Hazratganj Police Station", "city_id": "3003" },
  { "id": "20012", "name": "T. Nagar Police Station", "city_id": "3004" },

  { "id": "20013", "name": "Angeles City Police Station", "city_id": "4001" },
  { "id": "20014", "name": "Quezon City Police Station", "city_id": "4002" },
  { "id": "20015", "name": "Iloilo City Police Station", "city_id": "4003" },
  { "id": "20016", "name": "Davao City Police Station", "city_id": "4004" },

  { "id": "20017", "name": "Bambalapitiya Police Station", "city_id": "5001" },
  { "id": "20018", "name": "Galle Police Station", "city_id": "5002" },
  { "id": "20019", "name": "Kandy Police Station", "city_id": "5003" },
  { "id": "20020", "name": "Batticaloa Police Station", "city_id": "5004" },

  { "id": "20021", "name": "Ramna Police Station", "city_id": "6001" },
  { "id": "20022", "name": "Kotwali Police Station", "city_id": "6002" },
  { "id": "20023", "name": "Khalishpur Police Station", "city_id": "6003" },
  { "id": "20024", "name": "Boalia Police Station", "city_id": "6004" }
];

export const post_offices = [
  { "id": "30001", "name": "Kathmandu GPO", "police_station_id": "20001" },
  { "id": "30002", "name": "Pokhara GPO", "police_station_id": "20002" },
  { "id": "30003", "name": "Biratnagar GPO", "police_station_id": "20003" },
  { "id": "30004", "name": "Janakpur GPO", "police_station_id": "20004" },

  { "id": "30005", "name": "Peshawar GPO", "police_station_id": "20005" },
  { "id": "30006", "name": "Lahore GPO", "police_station_id": "20006" },
  { "id": "30007", "name": "Karachi GPO", "police_station_id": "20007" },
  { "id": "30008", "name": "Quetta GPO", "police_station_id": "20008" },

  { "id": "30009", "name": "Mumbai GPO", "police_station_id": "20009" },
  { "id": "30010", "name": "Kolkata GPO", "police_station_id": "20010" },
  { "id": "30011", "name": "Lucknow GPO", "police_station_id": "20011" },
  { "id": "30012", "name": "Chennai GPO", "police_station_id": "20012" },

  { "id": "30013", "name": "Angeles City Post Office", "police_station_id": "20013" },
  { "id": "30014", "name": "Quezon City Post Office", "police_station_id": "20014" },
  { "id": "30015", "name": "Iloilo City Post Office", "police_station_id": "20015" },
  { "id": "30016", "name": "Davao City Post Office", "police_station_id": "20016" },

  { "id": "30017", "name": "Colombo GPO", "police_station_id": "20017" },
  { "id": "30018", "name": "Galle Post Office", "police_station_id": "20018" },
  { "id": "30019", "name": "Kandy Post Office", "police_station_id": "20019" },
  { "id": "30020", "name": "Batticaloa Post Office", "police_station_id": "20020" },

  { "id": "30021", "name": "Dhaka GPO", "police_station_id": "20021" },
  { "id": "30022", "name": "Chittagong GPO", "police_station_id": "20022" },
  { "id": "30023", "name": "Khulna GPO", "police_station_id": "20023" },
  { "id": "30024", "name": "Rajshahi GPO", "police_station_id": "20024" }
];

export const countryCode = [
  { "id": "1", "name": "Afghanistan", "code": "+93", "shortName": "AF" },
  { "id": "2", "name": "Albania", "code": "+355", "shortName": "AL" },
  { "id": "3", "name": "Algeria", "code": "+213", "shortName": "DZ" },
  { "id": "4", "name": "Andorra", "code": "+376", "shortName": "AD" },
  { "id": "5", "name": "Angola", "code": "+244", "shortName": "AO" },
  { "id": "6", "name": "Antigua and Barbuda", "code": "+1-268", "shortName": "AG" },
  { "id": "7", "name": "Argentina", "code": "+54", "shortName": "AR" },
  { "id": "8", "name": "Armenia", "code": "+374", "shortName": "AM" },
  { "id": "9", "name": "Australia", "code": "+61", "shortName": "AU" },
  { "id": "10", "name": "Austria", "code": "+43", "shortName": "AT" },
  { "id": "11", "name": "Azerbaijan", "code": "+994", "shortName": "AZ" },
  { "id": "12", "name": "Bahamas", "code": "+1-242", "shortName": "BS" },
  { "id": "13", "name": "Bahrain", "code": "+973", "shortName": "BH" },
  { "id": "14", "name": "Bangladesh", "code": "+880", "shortName": "BD" },
  { "id": "15", "name": "Barbados", "code": "+1-246", "shortName": "BB" },
  { "id": "16", "name": "Belarus", "code": "+375", "shortName": "BY" },
  { "id": "17", "name": "Belgium", "code": "+32", "shortName": "BE" },
  { "id": "18", "name": "Belize", "code": "+501", "shortName": "BZ" },
  { "id": "19", "name": "Benin", "code": "+229", "shortName": "BJ" },
  { "id": "20", "name": "Bhutan", "code": "+975", "shortName": "BT" },
  { "id": "21", "name": "Bolivia", "code": "+591", "shortName": "BO" },
  { "id": "22", "name": "Bosnia and Herzegovina", "code": "+387", "shortName": "BA" },
  { "id": "23", "name": "Botswana", "code": "+267", "shortName": "BW" },
  { "id": "24", "name": "Brazil", "code": "+55", "shortName": "BR" },
  { "id": "25", "name": "Brunei", "code": "+673", "shortName": "BN" },
  { "id": "26", "name": "Bulgaria", "code": "+359", "shortName": "BG" },
  { "id": "27", "name": "Burkina Faso", "code": "+226", "shortName": "BF" },
  { "id": "28", "name": "Burundi", "code": "+257", "shortName": "BI" },
  { "id": "29", "name": "Cabo Verde", "code": "+238", "shortName": "CV" },
  { "id": "30", "name": "Cambodia", "code": "+855", "shortName": "KH" },
  { "id": "31", "name": "Cameroon", "code": "+237", "shortName": "CM" },
  { "id": "32", "name": "Canada", "code": "+1", "shortName": "CA" },
  { "id": "33", "name": "Central African Republic", "code": "+236", "shortName": "CF" },
  { "id": "34", "name": "Chad", "code": "+235", "shortName": "TD" },
  { "id": "35", "name": "Chile", "code": "+56", "shortName": "CL" },
  { "id": "36", "name": "China", "code": "+86", "shortName": "CN" },
  { "id": "37", "name": "Colombia", "code": "+57", "shortName": "CO" },
  { "id": "38", "name": "Comoros", "code": "+269", "shortName": "KM" },
  { "id": "39", "name": "Congo (Congo-Brazzaville)", "code": "+242", "shortName": "CG" },
  { "id": "40", "name": "Costa Rica", "code": "+506", "shortName": "CR" },
  { "id": "41", "name": "Croatia", "code": "+385", "shortName": "HR" },
  { "id": "42", "name": "Cuba", "code": "+53", "shortName": "CU" },
  { "id": "43", "name": "Cyprus", "code": "+357", "shortName": "CY" },
  { "id": "44", "name": "Czechia (Czech Republic)", "code": "+420", "shortName": "CZ" },
  { "id": "45", "name": "Denmark", "code": "+45", "shortName": "DK" },
  { "id": "46", "name": "Djibouti", "code": "+253", "shortName": "DJ" },
  { "id": "47", "name": "Dominica", "code": "+1-767", "shortName": "DM" },
  { "id": "48", "name": "Dominican Republic", "code": "+1-809", "shortName": "DO" },
  { "id": "49", "name": "East Timor (Timor-Leste)", "code": "+670", "shortName": "TL" },
  { "id": "50", "name": "Ecuador", "code": "+593", "shortName": "EC" },
  { "id": "51", "name": "Egypt", "code": "+20", "shortName": "EG" },
  { "id": "52", "name": "El Salvador", "code": "+503", "shortName": "SV" },
  { "id": "53", "name": "Equatorial Guinea", "code": "+240", "shortName": "GQ" },
  { "id": "54", "name": "Eritrea", "code": "+291", "shortName": "ER" },
  { "id": "55", "name": "Estonia", "code": "+372", "shortName": "EE" },
  { "id": "56", "name": "Eswatini", "code": "+268", "shortName": "SZ" },
  { "id": "57", "name": "Ethiopia", "code": "+251", "shortName": "ET" },
  { "id": "58", "name": "Fiji", "code": "+679", "shortName": "FJ" },
  { "id": "59", "name": "Finland", "code": "+358", "shortName": "FI" },
  { "id": "60", "name": "France", "code": "+33", "shortName": "FR" },
  { "id": "61", "name": "Gabon", "code": "+241", "shortName": "GA" },
  { "id": "62", "name": "Gambia", "code": "+220", "shortName": "GM" },
  { "id": "63", "name": "Georgia", "code": "+995", "shortName": "GE" },
  { "id": "64", "name": "Germany", "code": "+49", "shortName": "DE" },
  { "id": "65", "name": "Ghana", "code": "+233", "shortName": "GH" },
  { "id": "66", "name": "Greece", "code": "+30", "shortName": "GR" },
  { "id": "67", "name": "Grenada", "code": "+1-473", "shortName": "GD" },
  { "id": "68", "name": "Guatemala", "code": "+502", "shortName": "GT" },
  { "id": "69", "name": "Guinea", "code": "+224", "shortName": "GN" },
  { "id": "70", "name": "Guinea-Bissau", "code": "+245", "shortName": "GW" },
  { "id": "71", "name": "Guyana", "code": "+592", "shortName": "GY" },
  { "id": "72", "name": "Haiti", "code": "+509", "shortName": "HT" },
  { "id": "73", "name": "Honduras", "code": "+504", "shortName": "HN" },
  { "id": "74", "name": "Hungary", "code": "+36", "shortName": "HU" },
  { "id": "75", "name": "Iceland", "code": "+354", "shortName": "IS" },
  { "id": "76", "name": "India", "code": "+91", "shortName": "IN" },
  { "id": "77", "name": "Indonesia", "code": "+62", "shortName": "ID" },
  { "id": "78", "name": "Iran", "code": "+98", "shortName": "IR" },
  { "id": "79", "name": "Iraq", "code": "+964", "shortName": "IQ" },
  { "id": "80", "name": "Ireland", "code": "+353", "shortName": "IE" },
  { "id": "81", "name": "Israel", "code": "+972", "shortName": "IL" },
  { "id": "82", "name": "Italy", "code": "+39", "shortName": "IT" },
  { "id": "83", "name": "Jamaica", "code": "+1-876", "shortName": "JM" },
  { "id": "84", "name": "Japan", "code": "+81", "shortName": "JP" },
  { "id": "85", "name": "Jordan", "code": "+962", "shortName": "JO" },
  { "id": "86", "name": "Kazakhstan", "code": "+7", "shortName": "KZ" },
  { "id": "87", "name": "Kenya", "code": "+254", "shortName": "KE" },
  { "id": "88", "name": "Kiribati", "code": "+686", "shortName": "KI" },
  { "id": "89", "name": "Korea (North)", "code": "+850", "shortName": "KP" },
  { "id": "90", "name": "Korea (South)", "code": "+82", "shortName": "KR" },
  { "id": "91", "name": "Kosovo", "code": "+383", "shortName": "XK" },
  { "id": "92", "name": "Kuwait", "code": "+965", "shortName": "KW" },
  { "id": "93", "name": "Kyrgyzstan", "code": "+996", "shortName": "KG" },
  { "id": "94", "name": "Laos", "code": "+856", "shortName": "LA" },
  { "id": "95", "name": "Latvia", "code": "+371", "shortName": "LV" },
  { "id": "96", "name": "Lebanon", "code": "+961", "shortName": "LB" },
  { "id": "97", "name": "Lesotho", "code": "+266", "shortName": "LS" },
  { "id": "98", "name": "Liberia", "code": "+231", "shortName": "LR" },
  { "id": "99", "name": "Libya", "code": "+218", "shortName": "LY" },
  { "id": "100", "name": "Liechtenstein", "code": "+423", "shortName": "LI" },
  { "id": "101", "name": "Lithuania", "code": "+370", "shortName": "LT" },
  { "id": "102", "name": "Luxembourg", "code": "+352", "shortName": "LU" },
  { "id": "103", "name": "Madagascar", "code": "+261", "shortName": "MG" },
  { "id": "104", "name": "Malawi", "code": "+265", "shortName": "MW" },
  { "id": "105", "name": "Malaysia", "code": "+60", "shortName": "MY" },
  { "id": "106", "name": "Maldives", "code": "+960", "shortName": "MV" },
  { "id": "107", "name": "Mali", "code": "+223", "shortName": "ML" },
  { "id": "108", "name": "Malta", "code": "+356", "shortName": "MT" },
  { "id": "109", "name": "Marshall Islands", "code": "+692", "shortName": "MH" },
  { "id": "110", "name": "Mauritania", "code": "+222", "shortName": "MR" },
  { "id": "111", "name": "Mauritius", "code": "+230", "shortName": "MU" },
  { "id": "112", "name": "Mexico", "code": "+52", "shortName": "MX" },
  { "id": "113", "name": "Micronesia", "code": "+691", "shortName": "FM" },
  { "id": "114", "name": "Moldova", "code": "+373", "shortName": "MD" },
  { "id": "115", "name": "Monaco", "code": "+377", "shortName": "MC" },
  { "id": "116", "name": "Mongolia", "code": "+976", "shortName": "MN" },
  { "id": "117", "name": "Montenegro", "code": "+382", "shortName": "ME" },
  { "id": "118", "name": "Morocco", "code": "+212", "shortName": "MA" },
  { "id": "119", "name": "Mozambique", "code": "+258", "shortName": "MZ" },
  { "id": "120", "name": "Myanmar (Burma)", "code": "+95", "shortName": "MM" },
  { "id": "121", "name": "Namibia", "code": "+264", "shortName": "NA" },
  { "id": "122", "name": "Nauru", "code": "+674", "shortName": "NR" },
  { "id": "123", "name": "Nepal", "code": "+977", "shortName": "NP" },
  { "id": "124", "name": "Netherlands", "code": "+31", "shortName": "NL" },
  { "id": "125", "name": "New Zealand", "code": "+64", "shortName": "NZ" },
  { "id": "126", "name": "Nicaragua", "code": "+505", "shortName": "NI" },
  { "id": "127", "name": "Niger", "code": "+227", "shortName": "NE" },
  { "id": "128", "name": "Nigeria", "code": "+234", "shortName": "NG" },
  { "id": "129", "name": "North Macedonia", "code": "+389", "shortName": "MK" },
  { "id": "130", "name": "Norway", "code": "+47", "shortName": "NO" },
  { "id": "131", "name": "Oman", "code": "+968", "shortName": "OM" },
  { "id": "132", "name": "Pakistan", "code": "+92", "shortName": "PK" },
  { "id": "133", "name": "Palau", "code": "+680", "shortName": "PW" },
  { "id": "134", "name": "Panama", "code": "+507", "shortName": "PA" },
  { "id": "135", "name": "Papua New Guinea", "code": "+675", "shortName": "PG" },
  { "id": "136", "name": "Paraguay", "code": "+595", "shortName": "PY" },
  { "id": "137", "name": "Peru", "code": "+51", "shortName": "PE" },
  { "id": "138", "name": "Philippine", "code": "+63", "shortName": "PH" },
  { "id": "139", "name": "Poland", "code": "+48", "shortName": "PL" },
  { "id": "140", "name": "Portugal", "code": "+351", "shortName": "PT" },
  { "id": "141", "name": "Qatar", "code": "+974", "shortName": "QA" },
  { "id": "142", "name": "Romania", "code": "+40", "shortName": "RO" },
  { "id": "143", "name": "Russia", "code": "+7", "shortName": "RU" },
  { "id": "144", "name": "Rwanda", "code": "+250", "shortName": "RW" },
  { "id": "145", "name": "Saint Kitts and Nevis", "code": "+1-869", "shortName": "KN" },
  { "id": "146", "name": "Saint Lucia", "code": "+1-758", "shortName": "LC" },
  { "id": "147", "name": "Saint Vincent and the Grenadines", "code": "+1-784", "shortName": "VC" },
  { "id": "148", "name": "Samoa", "code": "+685", "shortName": "WS" },
  { "id": "149", "name": "San Marino", "code": "+378", "shortName": "SM" },
  { "id": "150", "name": "Sao Tome and Principe", "code": "+239", "shortName": "ST" },
  { "id": "151", "name": "Saudi Arabia", "code": "+966", "shortName": "SA" },
  { "id": "152", "name": "Senegal", "code": "+221", "shortName": "SN" },
  { "id": "153", "name": "Serbia", "code": "+381", "shortName": "RS" },
  { "id": "154", "name": "Seychelles", "code": "+248", "shortName": "SC" },
  { "id": "155", "name": "Sierra Leone", "code": "+232", "shortName": "SL" },
  { "id": "156", "name": "Singapore", "code": "+65", "shortName": "SG" },
  { "id": "157", "name": "Slovakia", "code": "+421", "shortName": "SK" },
  { "id": "158", "name": "Slovenia", "code": "+386", "shortName": "SI" },
  { "id": "159", "name": "Solomon Islands", "code": "+677", "shortName": "SB" },
  { "id": "160", "name": "Somalia", "code": "+252", "shortName": "SO" },
  { "id": "161", "name": "South Africa", "code": "+27", "shortName": "ZA" },
  { "id": "162", "name": "South Sudan", "code": "+211", "shortName": "SS" },
  { "id": "163", "name": "Spain", "code": "+34", "shortName": "ES" },
  { "id": "164", "name": "Sri Lanka", "code": "+94", "shortName": "LK" },
  { "id": "165", "name": "Sudan", "code": "+249", "shortName": "SD" },
  { "id": "166", "name": "Suriname", "code": "+597", "shortName": "SR" },
  { "id": "167", "name": "Sweden", "code": "+46", "shortName": "SE" },
  { "id": "168", "name": "Switzerland", "code": "+41", "shortName": "CH" },
  { "id": "169", "name": "Syria", "code": "+963", "shortName": "SY" },
  { "id": "170", "name": "Taiwan", "code": "+886", "shortName": "TW" },
  { "id": "171", "name": "Tajikistan", "code": "+992", "shortName": "TJ" },
  { "id": "172", "name": "Tanzania", "code": "+255", "shortName": "TZ" },
  { "id": "173", "name": "Thailand", "code": "+66", "shortName": "TH" },
  { "id": "174", "name": "Timor-Leste", "code": "+670", "shortName": "TL" },
  { "id": "175", "name": "Togo", "code": "+228", "shortName": "TG" },
  { "id": "176", "name": "Tonga", "code": "+676", "shortName": "TO" },
  { "id": "177", "name": "Trinidad and Tobago", "code": "+1-868", "shortName": "TT" },
  { "id": "178", "name": "Tunisia", "code": "+216", "shortName": "TN" },
  { "id": "179", "name": "Turkey", "code": "+90", "shortName": "TR" },
  { "id": "180", "name": "Turkmenistan", "code": "+993", "shortName": "TM" },
  { "id": "181", "name": "Tuvalu", "code": "+688", "shortName": "TV" },
  { "id": "182", "name": "Uganda", "code": "+256", "shortName": "UG" },
  { "id": "183", "name": "Ukraine", "code": "+380", "shortName": "UA" },
  { "id": "184", "name": "United Arab Emirates", "code": "+971", "shortName": "AE" },
  { "id": "185", "name": "United Kingdom", "code": "+44", "shortName": "GB" },
  { "id": "186", "name": "United States", "code": "+1", "shortName": "US" },
  { "id": "187", "name": "Uruguay", "code": "+598", "shortName": "UY" },
  { "id": "188", "name": "Uzbekistan", "code": "+998", "shortName": "UZ" },
  { "id": "189", "name": "Vanuatu", "code": "+678", "shortName": "VU" },
  { "id": "190", "name": "Vatican City", "code": "+379", "shortName": "VA" },
  { "id": "191", "name": "Venezuela", "code": "+58", "shortName": "VE" },
  { "id": "192", "name": "Vietnam", "code": "+84", "shortName": "VN" },
  { "id": "193", "name": "Yemen", "code": "+967", "shortName": "YE" },
  { "id": "194", "name": "Zambia", "code": "+260", "shortName": "ZM" },
  { "id": "195", "name": "Zimbabwe", "code": "+263", "shortName": "ZW" }
];

export const religion = [
  {
    "id": "1",
    "name": "Islam",
  },
  {
    "id": "2",
    "name": "Hinduism",
  },
  {
    "id": "3",
    "name": "Christianity",
  },
  {
    "id": "4",
    "name": "Buddhism",
  },
]

export const hiringPositions = [
  { id: 1, name: "Account Super Intendent" },
  { id: 2, name: "Accounting Assistant" },
  { id: 3, name: "Accounting Clerk / General" },
  { id: 4, name: "Accounting Executive" },
  { id: 5, name: "Administrative Affairs Clerks" },
  { id: 6, name: "Admin Assistant" },
  { id: 7, name: "Administrative Assistant" },
  { id: 8, name: "Administration Coord." },
  { id: 9, name: "Administration Executive" },
  { id: 10, name: "Administrative Officer" },
  { id: 11, name: "Administrative Superintendent" },
  { id: 12, name: "Administrative Supervisor Assistant" },
  { id: 13, name: "Administrative Training Superintendent" },
  { id: 14, name: "Administrator" },
  { id: 15, name: "Administrator / General" },
  { id: 16, name: "Administration Worker" },
  { id: 17, name: "Advertising Representative" },
  { id: 18, name: "Assistant Accountant" },
  { id: 19, name: "Assistant Admin Officer" },
  { id: 20, name: "Asst Admin Superintendent" },
  { id: 21, name: "Asst Security Incharge" },
  { id: 22, name: "Attendance Clerk" },
  { id: 23, name: "Auditing Clerk" },
  { id: 24, name: "Building Cleaner (Outside)" },
  { id: 25, name: "Building Supervisor" },
  { id: 26, name: "Car Electrician Assistant" },
  { id: 27, name: "Car Rental Clerk" },
  { id: 28, name: "Cash Desk Clerk" },
  { id: 29, name: "Cashier" },
  { id: 30, name: "Cashier Supervisor" },
  { id: 31, name: "Civil Registers Clerk" },
  { id: 32, name: "Cleaner / General" },
  { id: 33, name: "Cleaning Worker" },
  { id: 34, name: "Clerical Supervisors" },
  { id: 35, name: "Clerk Assistant" },
  { id: 36, name: "Commercial Broker" },
  { id: 37, name: "Computer Data Entry Operator" },
  { id: 38, name: "Computer Operator" },
  { id: 39, name: "Content Developer" },
  { id: 40, name: "Contracts & Purchases- Assistance" },
  { id: 41, name: "Customer Service Representative" },
  { id: 42, name: "Customer Services Assistant" },
  { id: 43, name: "Data Entry Assistant" },
  { id: 44, name: "Data Entry Clerk" },
  { id: 45, name: "Deputy Customer Service" },
  { id: 46, name: "Document Scanning Clerk" },
  { id: 47, name: "Documents Clerk/Account" },
  { id: 48, name: "Domestic Supervisor" },
  { id: 49, name: "Employment Services Clerk" },
  { id: 50, name: "Event Coordinator" },
  { id: 51, name: "Excellent Clerk" },
  { id: 52, name: "Executive Assistant" },
  { id: 53, name: "Executive Personal Ass." },
  { id: 54, name: "Executive Secretary" },
  { id: 55, name: "Exhibitions Coordinator" },
  { id: 56, name: "Filer Assistant" },
  { id: 57, name: "Filer General" },
  { id: 58, name: "Filing Work Supervisor" },
  { id: 59, name: "Financial Representative" },
  { id: 60, name: "General Helper" },
  { id: 61, name: "General Services Professions" },
  { id: 62, name: "General Workers" },
  { id: 63, name: "Graphics Operator" },
  { id: 64, name: "Head Waiter/Waitress" },
  { id: 65, name: "Health Care Attendant" },
  { id: 66, name: "Help Desk Clerk" },
  { id: 67, name: "Helpdesk Operator" },
  { id: 68, name: "House Supervisor" },
  { id: 69, name: "House Supervisor Assistant" },
  { id: 70, name: "Household Appliance Electricin" },
  { id: 71, name: "Household Appliances Assembler" },
  { id: 72, name: "Housing Officer" },
  { id: 73, name: "HR Assistant" },
  { id: 74, name: "HR Coordinator" },
  { id: 75, name: "Human Resource Coordinator" },
  { id: 76, name: "Information Clerks" },
  { id: 77, name: "Lawyer Clerk" },
  { id: 78, name: "Legal Clerks" },
  { id: 79, name: "Library Clerk" },
  { id: 80, name: "Marketing Assistant" },
  { id: 81, name: "Medical Instruments Sales" },
  { id: 82, name: "Men Barber" },
  { id: 83, name: "Men Clothes Sales" },
  { id: 84, name: "Messengers Head" },
  { id: 85, name: "Metal Worker" },
  { id: 86, name: "Metallic Furniture Sales" },
  { id: 87, name: "Metals Sales" },
  { id: 88, name: "Office Clerks General" },
  { id: 89, name: "Office Supervisor" },
  { id: 90, name: "Office Worker" },
  { id: 91, name: "Paintings Sales" },
  { id: 92, name: "Passengers Service Clerk" },
  { id: 93, name: "Pavement Supervisor" },
  { id: 94, name: "Personal Assistant" },
  { id: 95, name: "Personal Affairs Supervisor" },
  { id: 96, name: "Personnel Affairs Clerk" },
  { id: 97, name: "Personnel Assistant" },
  { id: 98, name: "Pharmacist Assistant" },
  { id: 99, name: "Planning Assistant" },
  { id: 100, name: "Plant Operator" }
];



