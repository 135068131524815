const AcceptTermsAb = ({ position }) => {
    return (
        <>
            {position == 52 && (
                <div dir="auto">
                    <h3 class="text-[16px] font-medium text-[#111928] mb-2">تعيين</h3>

                    <p class="text-[14px] font-normal text-[#4B5563]">
                    أولا. يجب أن يعمل الموظف لدى الشركة في منصب وظيفي لمدة محددة مدتها سنتان )2( تبدأ من تاريخ البدء.
                    ثانيا. يجب على الموظف تنفيذ الواجبات وأداء المهام التي يؤديها عادة موظف ذو تسمية مماثلة، والتي تشمل، على سبيل المثال
                    لا الحصر، الوصف الوظيفي الأساسي.
                    ثالثا. يتفق الطرفان على أن أي تعديل على شروط عقد العمل هذا يجب أن يتم الاتفاق عليه كتابيًا بين الطرفين.
                    رابعا. تتعهد الشركة بإخطار الهيئة بأي تغيير في شروط التوظيف فيما يتعلق بالمنصب الوظيفي للموظف ومسؤولياته وراتبه
                    الأساسي والبدلات والمزايا الأخرى.
                    v. بعد انتهاء العقد محدد المدة لمدة سنتين )2(، يجوز للشركة تمديد أو تجديد مدة عقد الموظف. إذا استمر الموظف في الوظيفة بعد انتهاء مدة العقد، فإن ذلك يُعتبر استمرارًا للعقد.
                    </p>

                    <h3 class="text-[16px] font-medium text-[#111928] mb-2">مسؤوليات والتزامات الوكالة</h3>

                    <p class="text-[14px] font-normal text-[#4B5563]">
                    في جميع المعاملات المقابلة وغيرها من التعاملات مع راكبي الدراجة، سيشير مقدم الطلب بوضوح إلى أنه يعمل بصفته مقدم طلب الوظيفة للبائعين.
                    يجب أن يكون مقدم الطلب مسؤولًا عن التزاماته تجاه الفاتح بموجب العقود متوافقة ومتوافقة مع جميع القوانين المعمول بها في دولة الإمارات العربية المتحدة والمملكة المتحدة.
                    يجب على مقدم الطلب إبلاغ الفاتح بأي وجميع الظروف التي قد يكون لها تأثير على عمل العميل والعقود وتقديم المعلومات والتوصيات في الوقت المناسب لإزالة العقبات.
                    </p>

                    <h3 class="text-[16px] font-medium text-[#111928] mb-2">نهاية</h3>

                    <p class="text-[14px] font-normal text-[#4B5563]">
                    يجوز إنهاء عقد العمل هذا على النحو التالي.
                    أثناء أو عند انتهاء فترة الاختبار فورًا من قبل أي من الطرفين، مع فترة إشعار مدتها 14 يومًا.
                    بموافقة الطرفين المتبادلة، مع إشعار مدته 30 يومًا بشرط تسجيل موافقة الموظف كتابيًا؛ أو من قبل أي من الطرفين لسبب مشروع.
                    </p>

                    <h3 class="text-[16px] font-medium text-[#111928] mb-2">تذكرة السفر</h3>

                    <p class="text-[14px] font-normal text-[#4B5563]">
                    في الظروف التي يتم فيها تعيين الموظف من قبل الشركة من خارج دولة الإمارات، تتحمل الشركة تكلفة تذكرة سفر الموظف.
                    إذا كان الموظف من داخل دولة الإمارات ولم يواصل العمل، تتحمل الشركة تكلفة عودته إلى مكان عمله الأصلي.
                    </p>

                    <h3 class="text-[16px] font-medium text-[#111928] mb-2">السرية وحقوق الملكية الفكرية</h3>

                    <p class="text-[14px] font-normal text-[#4B5563]">
                    تظل المعلومات السرية المقدمة من CONQUEROR ملكًا خاصًا ولا يجوز استخدامها أو إعادة إنتاجها.
                    يتعهد مقدم الطلب بالحفاظ على سرية جميع المعلومات طوال مدة الاتفاقية وبعدها.
                    </p>

                    <h3 class="text-[16px] font-medium text-[#111928] mb-2">العلاقة بين الأطراف</h3>

                    <p class="text-[14px] font-normal text-[#4B5563]">
                    لا يجوز اعتبار هذه الاتفاقية شراكة أو مشروعًا مشتركًا بين الأطراف.
                    لا يعتبر أي شخص موظفًا لدى الطرف الآخر عند تنفيذ الالتزامات بموجب الاتفاقية.
                    </p>

                    <h3 class="text-[16px] font-medium text-[#111928] mb-2">القانون الذي يحكم</h3>

                    <p class="text-[14px] font-normal text-[#4B5563]">
                    تخضع هذه الاتفاقية والمفاوضات بين الأطراف للقانون المعمول به في الإمارات والمملكة المتحدة.
                    </p>

                    <h3 class="text-[16px] font-medium text-[#111928] mb-2">تنويه</h3>

                    <p class="text-[14px] font-normal text-[#4B5563]">
                    أوقع على جميع الصفحات وأقر بفهم الشروط والأحكام المذكورة دون أي تأثير خارجي.
                    </p>
                </div>
            )}

            {position == 50 && (
                <div dir="auto">
                    <p class="text-[14px] font-normal text-[#4B5563]">
                    ةكرش Conqueror Aspiration LLC بكارك  
                    تاجارد اقفو طورشلل ماكحلأاو ةيلاتلا : 
                    يطغتس كتفيظو ماهملا ةصصخملا يف عقوم ،ليمعلا متيسو رابتعا رهشلأا ةتسلا ىلولأا  
                    ةرتف ،رابتخا متيسو كرشن يف عقوم ليمعلا يف يأ ناكم يف تاراملإا ةيبرعلا ةدحتملا
                    </p>

                    <p class="text-[14px] font-normal text-[#4B5563]">
                    ي اذه ضرعلا طورشملا انلوصحل ىلع  عجرم ضرم ةقفاوملاو ةمزلالا نم تاطلسلا  
                    ةيموكحلا ةصتخملا  يف ةلود تاراملإا ةيبرعلا ةدحتملا نكمتتل نم لمعلا انعم . ،كلذل لا  
                    يغبني  كل  رابتعا اذه امازتلا اتباث نم انبناج ىتح كغلبن اننأب انيقلت اعجرم ايضرم انلصحو  
                    ىلع ةريشأت لمع . عضخي باطخ ضرعلا اذه كلوصحل ىلع  ةصخر ةدايق ةبكرم تاذ  
                    نيتلجع ةيراس لوعفملا يف ةلود تاراملإا ةيبرعلا ميدقتو ةخسن اهنم انيلإ .
                    </p>

                    <p class="text-[14px] font-normal text-[#4B5563]">
                    خ ةرتفلا يتلا اهقرغتست لوصحلل ىلع  ةصخر ةدايق ةبكرم تاذ نيتلجع  يف  تاراملإا  
                    ةيبرعلا ،ةدحتملا متيس كرشن يف عقوم ليمعلا دعاسمك عدوتسم نوكيسو كبتار لباقم كلذ  
                    امك يلي :
                    </p>

                    <h3 class="text-[16px] font-medium text-[#111928] mb-2">رتف رابتخا :</h3>

                    <p class="text-[14px] font-normal text-[#4B5563]">
                    نوكتس يف ةرتف رابتخا ةدمل ةتس رهشأ نم خيرات فيظوتلا . دمتعيسو  
                    كديكأت يئاهنلا ىلع حاجن مييقت كئادأ للاخ ةرتف رابتخلاا .
                    </p>

                    <h3 class="text-[16px] font-medium text-[#111928] mb-2">س لمعلا :</h3>

                    <p class="text-[14px] font-normal text-[#4B5563]">
                    12 ةعاس يف ،مويلا 6 مايأ يف ،عوبسلأا امك اهددحي ليمعلا عم موي  
                    ةزاجإ . بجي ىلع فظوملا ليجست لوخدلا جورخلاو اقفو ديعاوملل  ةددحملا لامكإو  
                    تاعاس لمعلا ميلستلاو اقفو لودجلل  ينمزلا . اذإ مل نكت ققحت فادهأ ءادلأا ليمعلل ىلع  
                    ساسأ ،يموي دقف نوكت كانه ةبوقع ىلع  يلامجإ كبتار يرهشلا . ،كلذل نم  مهملا ةيبلت  
                    عيمج فادهأ ءادلأا يتلا  لمشت تايلمع ميلستلا ءاغللإاو روضحلاو يأو فادهأ ىرخأ دق  
                    اهعضي  ليمعلا.
                    </p>

                    <h3 class="text-[16px] font-medium text-[#111928] mb-2">قع لمعلا :</h3>

                    <p class="text-[14px] font-normal text-[#4B5563]">
                    دقع لمعلا صاخلا كب وه دقع لمع ددحم ةدملا ةدمل نيماع . اقفو حئاولل  
                    ةيموكحلا ،ةيلاحلا نوكتس ةريشأت  لمعلا ةصاخلا كب ةحلاص ةدمل نيماع  ( نينثا ) ةلباقو  
                    ديدجتلل ةقفاومب  ةلدابتم.
                    </p>

                    <h3 class="text-[16px] font-medium text-[#111928] mb-2">فلا :</h3>

                    <p class="text-[14px] font-normal text-[#4B5563]">
                    للاخ ةرتف ،رابتخلاا نكمي ةكرشلل ءاهنإ دقع لمعلا راطخإب هتدم 14  
                    ( ةعبرأ رشع ) اموي . عمو  ،كلذ  زوجي فظوملل  ءاهنإ دقع لمعلا للاخ ةرتف رابتخلاا  
                    اقفو طورشل نيناوق لمعلا ةيلحملا ةرتفبو راطخإ امإ 30  اموي وأ 14 اموي ( امبسح  دق  
                    قبطني كيلع بجومب نيناوق لمعلا ةيلحملا ) ، طرشب هنأ  يف يأ نم  نيتلاحلا  
                    ،نيتروكذملا  قحي ةكرشلل لوصحلا ىلع ضيوعت نم بحاص لمع يلاتلا امك وه  
                    حومسم هب بجومب نيناوق لمعلا ةيلحملا . دعب ةرتف ،رابتخلاا زوجي  يلأ فرط ءاهنإ  
                    تامدخلا  راطخإب  هتدم رهش دحاو وأ  بتار لادب هنم . يف ةلاح ءاهنإ ةمدخلا ىلع  
                    ساسأ ءوس كولس ،ريطخ متيسف ءاهنإ ةمدخلا اروف . اذإ تررق ةلاقتسلاا للاخ  لوأ  
                    نيماع نم ،لمعلا  ظفتحت ةكرشلا اضيأ  قحلاب يف  مدع  كئاطعإ ءافعإ  لمعلل ىدل  
                    بحاص لمع رخآ  يف تاراملإا ةيبرعلا ةدحتملا . مزتلي فرطلا يناثلا   درب ةميق  
                    بيردتلا  ( يتلاو  ردقت  غلبمب 5000  مهرد ) دنع  ءاهنإ دقع لمعلا ضحمب  هتدارإ  
                    ( للاخ ةنس  ) نم خيرات ءدب دقعلا  كلذو نود للاخلإا تامازتلاب فرطلا لولأا  
                    ةينوناقلا وأ ةيدقاعتلا ( . بجي  نأ نوكي غلبملا قفتملا هيلع ةميقل  ةرتف  بيردتلا   
                    ابسانتم عم ةدم لمع فظوملا.
                    </p>

                    <h3 class="text-[16px] font-medium text-[#111928] mb-2">ضت حلاصملا :</h3>

                    <p class="text-[14px] font-normal text-[#4B5563]">
                    ءانثأ كلمع عم ،ةكرشلا نل  حمسي  كل طارخنلااب  يف يأ ةفيظو  
                    ىرخأ وأ يأ لمع وأ تادهعت ىرخأ وأ  يأ طاشن يراجت  رخآ فلاخب كنوك افظوم في  
                    هذه ةكرشلا .
                    </p>

                    <h3 class="text-[16px] font-medium text-[#111928] mb-2">لا حيراصتلاو ( جراخ دلابلا ):</h3>

                    <p class="text-[14px] font-normal text-[#4B5563]">
                    لمحتن ةفلكت حيراصت  ةماقلإا ءاضعلأ قيرفلا امدنع نوكت ةيرورض لمعلل  عم ةكرشلا  
                    . ةدم دقعلا ناتنس ةلباق ديدجتلل ةقفاومب ةلدابتم .
                    </p>

                    <h3 class="text-[16px] font-medium text-[#111928] mb-2">أتلا حيراصتلاو ( لخاد ةلودلا ):</h3>

                    <p class="text-[14px] font-normal text-[#4B5563]">
                    اذإ مت فيظوت فظوم لخاد ةلود تاراملإا ةيبرعلا ،ةدحتملا كانهف طرش يضقي جورخب فظوملا 
                    ةدوعلاو ةرم ىرخأ . لمحتيو فظوملا ةفاك فيلاكتلا . يفو لظ فورظ ،ةنيعم اننكمي ةدعاسملا 
                    اذإ بلط فظوملا رييغت ةلاح ةريشأتلا لخاد ةلودلا . عمو ،كلذ لمحتي فظوملا ةفلكتلا .
                    </p>

                    <h3 class="text-[16px] font-medium text-[#111928] mb-2">ةصاخ كب طقف :</h3>

                    <p class="text-[14px] font-normal text-[#4B5563]">
                    نوكتس ةيطغتلا ةينيمأتلا ةيبطلا  ةيونسلا 150,000 مهرد يتارامإ.
                    </p>

                    <h3 class="text-[16px] font-medium text-[#111928] mb-2">سلا :</h3>

                    <p class="text-[14px] font-normal text-[#4B5563]">
                    لا زوجي ،كل  ءاوس ءانثأ كلمع وأ ،هدعب ءاشفإ وأ مادختسا يأ تامولعم ةيرس  
                    صخت ةكرشلا ( وأ صخت وأ قلعتت يأب نم اهئلامع و / وأ اهنئابز ) يتلاو دق نوكت  
                    ىلع ملع اهب ءانثأ كلمع ىدل ةكرشلا امب  يف كلذ  ىلع ليبس لاثملا  لا  
                    رصحلا تامولعملا ةيرسلا ةقلعتملا نوؤشلاب ةيراجتلا ةكرشلل وأ يأ نم  
                    اهئلامع / اهنئابز يتلاو دق نوكت تلصو ىلإ كملع ءانثأ ؛كلمع بجيو كيلع ءانثأ كلمع  
                    هدعبو ذاختا عيمج تاطايتحلاا ةلوقعملا ظافحلل ىلع ةيرس عيمج هذه تامولعملا .
                    </p>

                    <p class="text-[14px] font-normal text-[#4B5563]">
                    لاا نيناوقلل تاسايسو ءلامعلا تاميلعتلاو : دهعتت لاثتملااب تاسايسل ةكرشلا  
                    ءلامعلاو مهتاميلعتو اميف قلعتي ةسايسب لوادت نيفظوملا ريياعملاو ةيقلاخلأا ريياعمو نمأ  
                    تامولعملا عيمجو تاسايسلا تاميلعتلاو ىرخلأا لومعملا اهب نم تقو رخلآ . ظفتحي  
                    ليمعلا قحلاب  يف ليدعت هتاسايس هتاميلعتو يتلا رثؤت ىلع طورش لمع فظوملا . ربتعي  
                    ريفوت تاسايس ليمعلا هتاميلعتو اءزج لا أزجتي  نم باطخ ضرعلا اذه . امك دهعتت  
                    لاثتملااب عيمجل نيناوقلا حئاوللاو يتلا قلعتت وأ رثؤت ىلع هلمع ىدل ليمعلا.
                    </p>

                    <h3 class="text-[16px] font-medium text-[#111928] mb-2">مدع ةسفانملا :</h3>

                    <p class="text-[14px] font-normal text-[#4B5563]">
                    لا زوجي فظوملل للاخ ةدم اذه دقعلا ةبسنلابو يبدل ةدمل ينثا رشع ( 12 ) ارهش دعب  
                    ءاهتنا وأ ءاهنإ اذه دقعلا يلأ ،ببس لكشب رشابم وأ ريغ ،رشابم ءاوس هتفصب  
                    اكلام وأ اكيرش وأ امهاسم وأ اريدم وأ افظوم وأ اراشتسم وأ اعزوم وأ لاثمم وأ ايعار  
                    وأ ريغ كلذ نأ سرامي وأ كراشي وأ نوكي اينعم وأ امتهم ةلوازمب يأ لمع وأ ةفيظو  
                    لخاد يبد دق لمكت لامعأ ةكرشلا وأ يأ نم تاكرشلا ةعباتلا اهل وأ تاكرشلا ةيعرفلا.
                    </p>

                    <h3 class="text-[16px] font-medium text-[#111928] mb-2">طورش ماكحأو ىرخأ :</h3>

                    <p class="text-[14px] font-normal text-[#4B5563]">
                    اذه ضرعلا طورشم امب يلي :
                    </p>

                    <p class="text-[14px] font-normal text-[#4B5563]">
                    صحف ام لبق ،فيظوتلا امب  يف كلذ ملاتسا عجرم  يضرم ةكرشلل . ققحتلا نم تامولعملا  
                    يتلا مت اهميدقت ةلاكولل ءانثأ ةيلمع  ميدقتلا . يف لاح تدجو ةكرشلا  كتامولعم  ريغ  
                    ةحيحصلا  ذختتس ةكرشلا  تاءارجإ  ةيبيدأت كدض . ءانب ىلع  تابلطتم ،ةكرشلا  
                    نكمي ةكرشلل كلقن ىلإ يأ ناكم لمع يف يأ ناكم  يف  تاراملإا ةيبرعلا ةدحتملا .
                    </p>

                    <p class="text-[14px] font-normal text-[#4B5563]">
                    س ةشقانم  عيمج  تارييغتلا يتلا أرطت ىلع كطورش كماكحأو لماكلاب  لبق اهذيفنت  
                    متيسو اهقيثوت فوسو بلطتت كعيقوت ىلع دقع  لّدعم .
                    </p>
                </div>
            )}
        </>
    );
}
 
export default AcceptTermsAb;